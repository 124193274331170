import {Alert, Badge, Box, Button, CheckIcon, Grid, Group, Radio, Stack, Text} from "@mantine/core";
import {IconCheck, IconStatusChange, IconTruckDelivery, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import {Notyf} from "notyf";
import { useState } from "react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    data?: any;
    setSelectedData: (data: any) => void;
    refetchData?: () => void;

    setOpenChangeStatusModal: (status: boolean) => void;
} & ModalProps;

const listStatus = [
    {label: "تاكيد", value: "confirmed"},
    {label: "المحاولة الاولى", value: "no_answer"},
    {label: "المحاولة الثانية", value: "no_answer_2"},
    {label: "المحاولة الثالثة", value: "no_answer_3"},
    {label: "إبلاغ", value: "reported"},
    {label: "مكرر", value: "duplicated"},
    {label: "الهاتف المغلق", value: "phone_closed"},
    {label: "الهاتف خطأ", value: "wrong_number"},
    {label: "ملغي", value: "cancelled"},
    {label: "مزؤر", value: "fake_order"},
    {label: "تم التوصيل", value: "delivred"},
    {label: "مسترجع", value: "returned"},
    {label: "تم الرفع", value: "uploaded"},
    {label: "مسترجع للتحقق", value: "returned_checked"},
    {label: "عند شركة الشحن", value: "shipping_company"},
    {label: "رفض", value: "closed"}
]

export const SelectStatus = ({data, setSelectedData, setOpenChangeStatusModal, ...props}: Props) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState('');

    const onFormSubmit = () => {
        setSelectedData({...data, id: data._id, status: status})
        setOpenChangeStatusModal(true)
        closeModal()
    };

    const closeModal = () => {
        props.onClose();
        setStatus('')
    };

    return (
        <Modal
            {...props} onClose={closeModal} size="md"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button variant="outline" color="gray" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>تاكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box px={20}>
                <Box py={8} px={10} bg={"gray.2"} style={{borderRadius: 5}}>
                    <Group justify={"flex-start"} gap={5}>
                        <IconStatusChange size={18} display={status && status !== "" ? "block" : "none"} style={{transform: "rotate(90deg)"}} />
                        <Stack gap={10}>
                            <Text fw={"bold"} size={"sm"}>
                                الحالة الاصلية:
                                <Text ms={5} span={true}>{
                                    data?.status === "pending" ? <Badge radius={'sm'} color='yellow' variant='light'>{t('tables.orders.badge01')}</Badge>
                                        : data?.status === "confirmed" ? <Badge radius={'sm'} color='green' variant='light'>{t('tables.orders.badge02')}</Badge>
                                            : data?.status === "closed" ? <Badge radius={'sm'} color='red' variant='light'>{t('tables.orders.badge03')}</Badge>
                                                : data?.status === "abandoned" ? <Badge radius={'sm'} color='orange' variant='light'>{t('tables.orders.badge04')}</Badge>

                                                    : data?.status === "no_answer" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الاولى"}</Badge>
                                                        : data?.status === "no_answer_2" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الثانية"}</Badge>
                                                            : data?.status === "no_answer_3" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الثالثة"}</Badge>
                                                                : data?.status === "reported" ? <Badge radius={'sm'} color='red' variant='light'>{"إبلاغ"}</Badge>
                                                                    : data?.status === "duplicated" ? <Badge radius={'sm'} color='orange' variant='light'>{"مكرر"}</Badge>
                                                                        : data?.status === "phone_closed" ? <Badge radius={'sm'} color='red' variant='light'>{"الهاتف المغلق"}</Badge>
                                                                            : data?.status === "wrong_number" ? <Badge radius={'sm'} color='orange' variant='light'>{"الهاتف خطأ"}</Badge>
                                                                                : data?.status === "cancelled" ? <Badge radius={'sm'} color='red' variant='light'>{"ملغي"}</Badge>
                                                                                    : data?.status === "fake_order" ? <Badge radius={'sm'} color='red' variant='light'>{"مزور"}</Badge>
                                                                                        : data?.status === "delivred" ? <Badge radius={'sm'} color='green' variant='light'>{"تم التوصيل"}</Badge>
                                                                                            : data?.status === "returned" ? <Badge radius={'sm'} color='yellow' variant='light'>{"مسترجع"}</Badge>
                                                                                                : data?.status === "uploaded" ? <Badge radius={'sm'} color='green' variant='light'>{"تم الرفع"}</Badge>
                                                                                                    : data?.status === "returned_checked" ? <Badge radius={'sm'} color='red' variant='light'>{"مسترجع للتحقق"}</Badge>
                                                                                                        : data?.status === "shipping_company" ? <Badge radius={'sm'} color='green' variant='light'>{"عند شركة الشحن"}</Badge>
                                                                                                            : null
                                }</Text>
                            </Text>
                            <Text display={status && status !== "" ? "block" : "none"} fw={"bold"} size={"sm"}>
                                الحالة الجديدة:
                                <Text ms={5} span={true}>{
                                    status === "pending" ? <Badge radius={'sm'} color='yellow' variant='light'>{t('tables.orders.badge01')}</Badge>
                                        : status === "confirmed" ? <Badge radius={'sm'} color='green' variant='light'>{t('tables.orders.badge02')}</Badge>
                                            : status === "closed" ? <Badge radius={'sm'} color='red' variant='light'>{t('tables.orders.badge03')}</Badge>
                                                : status === "abandoned" ? <Badge radius={'sm'} color='orange' variant='light'>{t('tables.orders.badge04')}</Badge>
                                                    : status === "no_answer" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الاولى"}</Badge>
                                                        : status === "no_answer_2" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الثانية"}</Badge>
                                                            : status === "no_answer_3" ? <Badge radius={'sm'} color='yellow' variant='light'>{"المحاولة الثالثة"}</Badge>
                                                                : status === "reported" ? <Badge radius={'sm'} color='red' variant='light'>{"إبلاغ"}</Badge>
                                                                    : status === "duplicated" ? <Badge radius={'sm'} color='orange' variant='light'>{"مكرر"}</Badge>
                                                                        : status === "phone_closed" ? <Badge radius={'sm'} color='red' variant='light'>{"الهاتف المغلق"}</Badge>
                                                                            : status === "wrong_number" ? <Badge radius={'sm'} color='orange' variant='light'>{"الهاتف خطأ"}</Badge>
                                                                                : status === "cancelled" ? <Badge radius={'sm'} color='red' variant='light'>{"ملغي"}</Badge>
                                                                                    : status === "fake_order" ? <Badge radius={'sm'} color='red' variant='light'>{"مزور"}</Badge>
                                                                                        : status === "delivred" ? <Badge radius={'sm'} color='green' variant='light'>{"تم التوصيل"}</Badge>
                                                                                            : status === "returned" ? <Badge radius={'sm'} color='yellow' variant='light'>{"مسترجع"}</Badge>
                                                                                                : status === "uploaded" ? <Badge radius={'sm'} color='green' variant='light'>{"تم الرفع"}</Badge>
                                                                                                    : status === "returned_checked" ? <Badge radius={'sm'} color='red' variant='light'>{"مسترجع للتحقق"}</Badge>
                                                                                                        : status === "shipping_company" ? <Badge radius={'sm'} color='green' variant='light'>{"عند شركة الشحن"}</Badge>
                                                                                                            : null
                                }</Text>
                            </Text>
                        </Stack>
                    </Group>
                </Box>
                <Grid gutter={0}>
                    <Grid.Col span={{ base: 12 }}>
                        <Radio.Group name="favoriteFramework" value={status} onChange={setStatus} >
                            <Stack mt="xs">
                                {listStatus.map((item, index: number) => (
                                    <Radio
                                        key={index} icon={CheckIcon}
                                        value={item?.value} color={"#323232"}
                                        // display={index === 1 ? "none" : "block"}
                                        label={
                                            <Group justify='space-between'>
                                                <Text fw={700} c={"gray.7"} >{item?.label}</Text>
                                            </Group>
                                        }
                                        styles={()=>({
                                            root: {
                                                background: "#fff",
                                                border: `2px solid ${"#fcd008"}7d`,
                                                borderRadius: 5,
                                                padding: "8px 10px"
                                            },
                                            body: { justifyContent: "flex-start" },
                                            labelWrapper: { width: "100%" },
                                            label: { width: "100%" }
                                        })}
                                    />
                                ))}
                            </Stack>
                        </Radio.Group>
                    </Grid.Col>
                </Grid>
            </Box>
        </Modal>
    );
};