import {
    ActionIcon,
    Box,
    Button,
    ColorInput,
    Divider, FileButton,
    Grid,
    Group,
    NumberInput, Stack,
    Image,
    Text,
    TextInput
} from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import {IconCheck, IconPlus, IconTrash, IconUpload, IconX} from "@tabler/icons-react";
import classes from "../../styles/AddProduct.module.css"
import { z } from 'zod';

import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useProducts } from "../../api";
import Wilayas from "../../helper/wilayas.json";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

interface FormDataI {
    variants: {
        color: string | null;
        size: string | null;
        quantity: number;
        image: File | null;
        imageUrl: string;
    }[]
}

const isBase64Image = (value: string): boolean => {
    const base64Regex = /^data:image\/(png|jpg|jpeg|gif|webp|svg);base64,/i;
    return base64Regex.test(value);
};

export const UpdateVariantProduct = (props: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values, setFieldValue, removeListItem} = useForm<FormDataI>({
        initialValues: {
            variants: []
        }
    });

    useEffect(() => {
        if (props.opened && "data" in props && props.data !== null) {
            const product = props.data

            if (product?.variants && product?.variants?.length > 0) {
                const listVariants: { color: string | null, size: string | null, quantity: number, image: any, imageUrl: string }[] = []
                for (let i = 0; i < product?.variants?.length; i++) {
                    const variant = product?.variants[i]
                    listVariants.push({ ...variant, image: null, imageUrl: variant.image })
                }

                if (product?.variants?.length < (product?.sizes?.length * product?.colors?.length)) {
                    for (let i = 0; i < product?.sizes?.length; i++) {
                        const size = product?.sizes[i]
                        for (let j = 0; j < product?.colors?.length; j++) {
                            const color = product?.colors[i]

                            const index = listVariants.findIndex((item: any) => item?.color === color && item?.size === size)
                            if (index < 0) {
                                listVariants.push({color, size, quantity: 0, image: null, imageUrl: ""})
                            }
                        }
                    }
                }

                setValues({ variants: listVariants })
            } else {
                const listVariants: { color: string | null, size: string | null, quantity: number, image: any, imageUrl: string }[] = []
                if ((product?.sizes && product?.sizes?.length > 0) || (product?.colors && product?.colors?.length > 0)) {
                    if ((product?.sizes && product?.sizes?.length > 0) && (product?.colors && product?.colors?.length > 0)) {
                        for (let i = 0; i < product?.sizes?.length; i++) {
                            const size = product?.sizes[i]
                            for (let j = 0; j < product?.colors?.length; j++) {
                                const color = product?.colors[i]
                                listVariants.push({color, size, quantity: 0, image: null, imageUrl: ""})
                            }
                        }
                    }
                }
                setValues({ variants: listVariants })
            }
        }
    }, [props.opened, props.data])

    const onSubmitForm = ({variants}: any) => {
        setLoading(true)
        let data = new FormData();
        // data.append('variants', variants);

        variants.forEach((item: any, index: number) => {
            data.append(`color.${index}`, item.color);
            data.append(`size.${index}`, item.size);
            data.append(`quantity.${index}`, item.quantity);
            if (item.image || (item.imageUrl && item.imageUrl !== "")) {
                data.append(`image.${index}`, item.image ? item.image : item.imageUrl);
            }
        });
        
        client.put(`/addVariants/${props.data._id}`, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.updateProduct.alert01'), color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: t('modals.updateProduct.alert02'), color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    const getURLFromFile = (index: number, file: any) => {
        const reader = new FileReader();

        if (file) {
            reader.readAsDataURL(file);

            reader.onload = (event: any) => {
                setFieldValue(`variants.${index}.imageUrl`, event.target.result)
            };
        }
    };



    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box px={10}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    {values.variants.map((item, index) => (
                        <Stack gap={5} mb={10}>
                            <Divider
                                orientation="horizontal"
                                label={
                                    <Group gap={5}>
                                        <Text size="xs" c={'#323232'}>{"المتغير"} {index + 1}</Text>
                                        <ActionIcon
                                            variant="transparent" color={'red'} size={"xs"}
                                            onClick={() => removeListItem('variants', index)}
                                        >
                                            <IconTrash size={18}/>
                                        </ActionIcon>
                                    </Group>
                                }
                                labelPosition="left"
                                mb={0}
                            />
                            <Group wrap={"nowrap"}>
                                {/*<FileButton*/}
                                {/*    onChange={(payload: any) => {*/}
                                {/*        setFieldValue(`variants.${index}.image`, payload)*/}
                                {/*        getURLFromFile(index, payload)*/}
                                {/*    }}*/}
                                {/*    accept="image/png,image/jpeg"*/}
                                {/*>*/}
                                {/*    {(props) => (*/}
                                {/*        <ActionIcon {...props} color={"#ccd2d9"} variant={"outline"} size={60}>*/}
                                {/*            {values.variants?.[index]?.imageUrl && values.variants?.[index]?.imageUrl !== ""*/}
                                {/*                ? isBase64Image(values.variants?.[index]?.imageUrl)*/}
                                {/*                    ? <Image src={values.variants?.[index]?.imageUrl} h={50} w={50} />*/}
                                {/*                    : <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${values.variants?.[index]?.imageUrl}`} h={50} w={50} />*/}
                                {/*                : <IconUpload size={20} color={"#333"}/>*/}
                                {/*            }*/}
                                {/*        </ActionIcon>*/}
                                {/*    )}*/}
                                {/*</FileButton>*/}
                                <Grid key={index} className={classes.cardOffer} mt={10} w={"100%"}>
                                    <Grid.Col span={4} >
                                        <TextInput
                                            label={"الحجم"}
                                            placeholder={"الحجم"}
                                            readOnly={true}
                                            {...getInputProps(`variants.${index}.size`)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} >
                                        <ColorInput
                                            label={"اللون"}
                                            placeholder={"اللون"}
                                            readOnly={true}
                                            {...getInputProps(`variants.${index}.color`)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={4} >
                                        <NumberInput
                                            label={"الكمية"}
                                            placeholder={"الكمية"}
                                            withAsterisk
                                            {...getInputProps(`variants.${index}.quantity`)}
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Group>
                        </Stack>
                    ))}
                </form>
            </Box>
        </Modal>
    );
};