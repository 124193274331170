import { ActionIcon, Box, Button, Checkbox, CloseButton, ColorPicker, Divider, Grid, Group, Image, MultiSelect, NumberInput, Select, Stack, Switch, Tabs, TagsInput, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCheck, IconCloudCheck, IconCloudUpload, IconColorSwatch, IconMinus, IconPhoto, IconPlus, IconTrash, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import classes from "../../styles/AddProduct.module.css"
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';

import { RichText } from "../Custom";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@mantine/hooks";
import { useProducts } from "../../api";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

const listColorHex = ['#FFFFFF', '#000000', '#9E9E9E', '#7C4DFF', '#F44336', '#4CAF50', '#FF9800', '#2196F3', '#FFEB3B', '#795548', "#f0e1ce", "#ad2852"]

export const UpdateAdditionalInformation = (props: Props) => {
    const { t } = useTranslation();
    const schema = z.object({
        name: z.string({message: t('modals.updateProduct.schemaName')}).min(2, { message: t('modals.updateProduct.schemaNam2') }),
        price: z.number({message: t('modals.updateProduct.schemaPrice')}).gt(0, { message: t('modals.updateProduct.schemaPrice2') }),
        cost: z.number({message: t('modals.updateProduct.schemaCost')}),
        shortDescription:  z.string({message: t('modals.updateProduct.schemaShortDescription')}).min(2, { message: t('modals.updateProduct.schemaShortDescription2') }),
        offers: z.array(
            z.object({
                name: z.string({message: t('modals.updateProduct.schemaOffersName')}).min(2, { message: t('modals.updateProduct.schemaOffersName2') }),
                price: z.number({message: t('modals.updateProduct.schemaOffersPrice')}).gt(0, { message: t('modals.updateProduct.schemaOffersPrice2') }),
                quantity: z.number({message: t('modals.updateProduct.schemaOffersQuantity')}).gt(0, { message: t('modals.updateProduct.schemaOffersQuantity2') })
            })
        )
    });
    const [loading, setLoading] = useState(false);
    let [description, setDescription] = useState("");

    const {loading: loadingProducts, data: dataProducts} = useProducts({
        idStore: cookies.get("id") || ""
    })

    useEffect(() => {
        if (props.opened && "data" in props && props.data !== null) {
            const product = props.data
            setDescription(product?.additionalDescription)
        }
    }, [props.opened, props.data])

    const onSubmitForm = () => {
        setLoading(true)
        let data = new FormData();
        data.append('additionalDescription', description);
        
        client.put(`/addAdditionalDescription/${props.data._id}`, {
            additionalDescription: description
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة المعلومات الاضافية للمنتج", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل اضافة المعلومات الاضافية للمنتج", color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    const closeModal = () => {
        setDescription("")
        props.onClose();
        setLoading(false)
    };
    
    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} onClick={onSubmitForm}>{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}} >
                <Grid gutter={20} justify="flex-start">
                    <Grid.Col span={{base: 12, md: 12}} >
                        <Text size="sm">{t('modals.updateProduct.label03')}</Text>
                        {description
                            ? <RichText
                                content={description}
                                setContent={setDescription}
                            />
                            :  <RichText
                                content={description}
                                setContent={setDescription}
                            />
                        }
                    </Grid.Col>
                </Grid>
            </Box>
        </Modal>
    );
};