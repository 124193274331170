import {ActionIcon, Divider, Group, Image, Menu, Skeleton, Stack, Text} from '@mantine/core';
import {
    IconTrash,
    IconCircleXFilled,
    IconCircleCheckFilled,
    IconEye,
    IconPencil,
    IconExternalLink,
    IconSettings,
    IconAdjustmentsHorizontal,
    IconDotsVertical,
    IconStatusChange,
    IconTruckDelivery,
    IconEdit,
    IconFileDescription, IconCubePlus
} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0",
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    }
};


type Props = {
    setSelectedData: (data: any) => void;

    setOpenEditModal: (status: boolean) => void;
    setOpenDeleteModal: (status: boolean) => void;
    setOpenShowModal: (status: boolean) => void;

    setOpenEditVariantModal: (status: boolean) => void;
    setOpenAdditionalInfoModal: (status: boolean) => void;
    setOpenAddQuantityModal: (status: boolean) => void;
    data?: any;
    loading?: boolean;
};

export const StockTable = ({
   data, loading, setSelectedData, setOpenEditModal, setOpenDeleteModal, setOpenShowModal, setOpenEditVariantModal,
   setOpenAdditionalInfoModal, setOpenAddQuantityModal
}: Props) => {
    const { t } = useTranslation();

    const columns = [
        {name: t('tables.products.row01'), selector: (row: any, index: number) => (
                <Group justify='flex-start' align='center' gap={8} wrap='nowrap'>
                    <Image
                        src={row?.thumbnail ? `${process.env.REACT_APP_API_URL_IMAGES}/${row?.thumbnail}` : "/no-image.png"}
                        width={44} height={44}
                    />
                    <Stack justify='center' align='flex-start' gap={5}>
                        <Text size='14px' c={"#333843"} fw={500}>{row.name}</Text>
                        <Text size='12px' c={"#667085"}>{`${row?.imagesProduct?.length || 0} ${t('tables.products.imagesLable')}`}</Text>
                    </Stack>
                </Group>
            ), minWidth: "250px",  },
        {name: t('tables.products.row02'), selector: (row: any, index: number) => (
            <Group gap={10}>
                <Text fz={"13px"} c={"#333843"}>
                    {row?.priceAfterDiscount > 0 ? row?.priceAfterDiscount : row?.price} {t('currency')}
                </Text>
                {row?.priceAfterDiscount > 0
                    ? <Text fz={"11px"} c="red" td="line-through">
                        {`${row.price} ${t('currency')}`}
                    </Text>
                    : null
                }
            </Group>
        ), minWidth: "160px", sortable: true, sortFunction: (a: any, b: any) => a?.price > b?.price ? 1 : -1 },
        {name: "الكمية في المخزون", selector: (row: any, index: number) => row?.quantity, minWidth: "180px" },
        {name: "المخزون الوهمي", selector: (row: any, index: number) => row?.quantity, minWidth: "180px" },

        {name: t('tables.products.row06'), selector: (row: any) => dayjs(row.createdAt).locale("ar").fromNow(), minWidth: "130px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 },

        {name: t('tables.products.row07'), cell: (row: any) => (
                <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                    <ActionIcon
                        variant={"transparent"} color={"#667085"}
                        onClick={() => {
                            setSelectedData(row)
                            setOpenShowModal(true)
                        }}
                    >
                        <IconEye size="1rem" />
                    </ActionIcon>

                    <ActionIcon
                        variant={"transparent"} color={"#667085"} component='a' target='_blank'
                        href={`${process.env.REACT_APP_STORE_URL}/product/${row._id}`}
                    >
                        <IconExternalLink size="1rem" />
                    </ActionIcon>

                    <Menu shadow="md" width={200} styles={{ dropdown: {maxHeight: 300, overflowY: "auto"} }}>
                        <Menu.Target>
                            <ActionIcon variant={"transparent"} color={"darck"}>
                                <IconDotsVertical size="1.125rem" />
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item
                                leftSection={<IconAdjustmentsHorizontal size={16} />}
                                onClick={() => {
                                    setSelectedData({...row})
                                    setOpenEditVariantModal(true)
                                }}
                            >{"اضافة وتعديل المتغيرات"}</Menu.Item>
                            <Menu.Item
                                leftSection={<IconCubePlus size={16} />}
                                onClick={() => {
                                    setSelectedData({...row})
                                    setOpenAddQuantityModal(true)
                                }}
                            >{"اضافة كمية"}</Menu.Item>
                            <Menu.Item
                                leftSection={<IconFileDescription size={16} />}
                                onClick={() => {
                                    setSelectedData({...row})
                                    setOpenAdditionalInfoModal(true)
                                }}
                            >{"اضافة معلومات جانبية"}</Menu.Item>

                            <Divider />
                            <Menu.Item
                                leftSection={<IconPencil size={16} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenEditModal(true)
                                }}
                            >{t('tables.orders.edit')}</Menu.Item>

                            <Menu.Item
                                leftSection={<IconTrash size={16} />}
                                onClick={() => {
                                    setSelectedData(row._id)
                                    setOpenDeleteModal(true)
                                }}
                            >{t('tables.orders.delete')}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            ), allowOverflow: true, button: true, minWidth: '170px' }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            conditionalRowStyles={[
                {
                    when: row => row?.quantity <= 10,
                    style: { backgroundColor: 'rgba(224,49,49,0.12)' }
                }
            ]}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }
            pagination={true}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={5}
        />
    );
};

const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {

    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={120} />
                    <Skeleton height={12} width={60} />
                </Stack>
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"11%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"14%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                    <Skeleton height={18} width={18} />
                    <Skeleton height={18} width={18} />
                    <Skeleton height={18} width={18} />
                </Group>
            </Group>
        </Group>
    );
}