import React, {useEffect, useRef, useState} from 'react';
import {ActionIcon, Box, Button, Chip, ChipGroupProps, Group} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {IconChevronLeft, IconChevronRight} from "@tabler/icons-react";



const listTags = [
    { label: "قيد الانتظار", value: 'pending', color: 'yellow' },
    { label: "مؤكد", value: 'confirmed', color: 'green' },
    { label: "مرفوض", value: 'closed', color: 'red' },

    { label: "المحاولة الاولى", value: 'no_answer', color: 'yellow' },
    { label: "المحاولة الثانية", value: 'no_answer_2', color: 'yellow' },
    { label: "المحاولة الثالثة", value: 'no_answer_3', color: 'yellow' },
    { label: "إبلاغ", value: 'reported', color: 'red' },
    { label: "مكرر", value: 'duplicated', color: 'orange' },
    { label: "الهاتف المغلق", value: 'phone_closed', color: 'red' },
    { label: "الهاتف خطأ", value: 'wrong_number', color: 'orange' },
    { label: "ملغي", value: 'cancelled', color: 'red' },
    { label: "مزؤر", value: 'fake_order', color: 'red' },
    { label: "تم التوصيل", value: 'delivred', color: 'green' },
    { label: "مسترجع", value: 'returned', color: 'yellow' },
    { label: "تم الرفع", value: 'uploaded', color: 'green' },
    { label: "مسترجع للتحقق", value: 'returned_checked', color: 'red' },
    { label: "عند شركة الشحن", value: 'shipping_company', color: 'green' },

    { label: "طلبات متروكة", value: 'abandoned', color: 'orange' },
]

interface Props extends ChipGroupProps {

};

export function CustomTagsGroup (props: Props) {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scroll = (direction: 'left' | 'right') => {
        if (containerRef.current) {
            const scrollAmount = direction === 'left' ? -200 : 200; // كمية التمرير
            containerRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth',
            });
        }
    };


    const handleMouseDown = (e: React.MouseEvent) => {
        if (!containerRef.current) return;
        setIsDragging(true);
        setStartX(e.clientX); // حفظ موقع الماوس عند بداية السحب
        setScrollLeft(containerRef.current.scrollLeft); // حفظ موقع التمرير الحالي
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !containerRef.current) return;
        const x = e.clientX; // موقع الماوس الحالي
        const walk = x - startX; // الفرق بين موقع البداية وموقع الماوس الحالي
        containerRef.current.scrollLeft = scrollLeft - walk; // تحديث التمرير
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    return (
        <Box pos={"relative"} w={"100%"} px={34}>
            <ActionIcon
                size={28}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                }}
                onClick={() => {
                    if (containerRef.current) {
                        containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
                    }
                }}
                variant="subtle"
                radius="xl"
            >
                <IconChevronLeft size={18} />
            </ActionIcon>
            <Chip.Group
                multiple={false}
                {...props}
            >
                <Group
                    w={"100%"} gap={5} wrap={"nowrap"}
                    ref={containerRef}
                    style={{
                        overflowX: 'auto',
                        scrollBehavior: 'smooth',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        WebkitOverflowScrolling: 'touch',
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                >
                    {listTags.map((item, index: number) => (
                        <Chip
                            key={index} value={item.value}
                            radius={"sm"} size={"sm"} variant={"light"}
                            color={item.color}
                            styles={{
                                iconWrapper: {
                                    display: "none"
                                },
                                label: {
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    border: "1px solid #E0E2E7",
                                }
                            }}
                        >{item.label}</Chip>
                    ))}
                </Group>
            </Chip.Group>

            <ActionIcon
                size={28}
                style={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                }}
                onClick={() => {
                    if (containerRef.current) {
                        containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
                    }
                }}
                variant="subtle"
                radius="xl"
            >
                <IconChevronRight size={18} />
            </ActionIcon>
        </Box>
    );
}