import { Carousel } from '@mantine/carousel';
import {
    Accordion,
    Badge,
    Box,
    Drawer,
    DrawerProps,
    Grid,
    Group,
    Image,
    Stack,
    Tabs,
    Text,
    Timeline, useMantineTheme
} from '@mantine/core';
import {IconCircleCheck, IconCircleX, IconShoppingCartFilled} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './../../styles/Product.module.css';
import dayjs from "dayjs";

type Props = {
    data?: any;
    setSelectedData?: (id: string) => void;
} & DrawerProps;

export function ShowProductDrawer(props: Props) {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string | null>('tab1');
    const [product, setProduct] = useState<any>(null);

    useEffect(() => {
        if ("data" in props && props.data !== null) {
            setProduct(props?.data)
        }
    }, [props.data])

    const closeModal = () => {
        props.onClose();
    };

    return (
        <Drawer
            {...props} onClose={closeModal}
            styles={{
                inner: {left: 0, right: 0 },
                body: {background: "#eee"},
                content: {background: "#eee"},
                header: {background: "#fff", padding: "0 10px"},
                title: {height: 60}
            }}
            title={
                <Tabs
                    defaultValue={activeTab} value={activeTab} onChange={setActiveTab}
                    styles={{
                        tab: {
                            height: 60,
                            paddingLeft: 0,
                            paddingRight: 0
                        },
                        list: {
                            flexWrap: 'nowrap',
                            // overflowX: 'scroll',
                            gap: 10
                        }
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="tab1" >
                            معلومات المنتج
                        </Tabs.Tab>
                        <Tabs.Tab value="tab2" >
                            سجل المنتج
                        </Tabs.Tab>
                    </Tabs.List>
                </Tabs>
            }
        >
            <Tabs defaultValue={activeTab} value={activeTab} onChange={setActiveTab} >
                <Tabs.Panel value="tab1" >
                    <Grid gutter={15} justify="center" h={"100%"}>
                        <Grid.Col span={12} >
                            <Carousel dir="rtl" slideSize="100%" height={250} slideGap={0} controlSize={24} loop withIndicators>
                                {product?.imagesProduct?.map((item: any, index: number) => (
                                    <Carousel.Slide key={index}>
                                        <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${item}`} alt="" fit="contain" height={250} />
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label01')}</Text>
                                <Text fw={600}>{product?.name}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label02')}</Text>
                                <Text fw={600}>{product?.price} {t("currency")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label03')}</Text>
                                <Text fw={600}>{product?.priceAfterDiscount} {t("currency")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label04')}</Text>
                                <Text fw={600}>{product?.cost} {t("currency")}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label05')}</Text>
                                <Text fw={600}>{product?.weight} {t('drawer.product.kg')}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Stack align='flex-start' bg={"#fff"} gap={5} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label06')}</Text>
                                <Text size='sm' fw={500} c={"gray.8"}>{product?.shortDescription}</Text>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Stack align='flex-start' bg={"#fff"} gap={5} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label07')}</Text>
                                <Box className={classes.richText} c={"gray.7"} dangerouslySetInnerHTML={{__html: product?.description}} />
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' align='center' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label08')}</Text>
                                {product?.freeShipping
                                    ? <IconCircleCheck size={20} color='green' />
                                    : <IconCircleX size={20} color='red' />
                                }
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label09')}</Text>
                                {product?.posting
                                    ? <IconCircleCheck size={20} color='green' />
                                    : <IconCircleX size={20} color='red' />
                                }
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{t('drawer.product.label10')}</Text>
                                {product?.upsell
                                    ? <IconCircleCheck size={20} color='green' />
                                    : <IconCircleX size={20} color='red' />
                                }
                            </Group>
                        </Grid.Col>
                        {product?.colors && product?.colors?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.product.label11')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {product?.colors?.map((item: string, index: number) => (
                                            <Box key={index} w={20} h={20} bg={item} style={{border: "1px solid #ddd", borderRadius: 3}} />
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {product?.sizes && product?.sizes?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.product.label12')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {product?.sizes?.map((item: string, index: number) => (
                                            item !== "" && <Badge key={index} variant="outline" color="gray">{item}</Badge>
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {product?.categories && product?.categories?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.product.label13')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {product?.categories?.map((item: string, index: number) => (
                                            item !== "" && <Badge key={index} variant="light" radius={"xs"} color="gray">{item}</Badge>
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {product?.subCategories && product?.subCategories?.length > 0
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.product.label14')}</Text>
                                    <Group justify='flex-start' align='center' gap={5}>
                                        {product?.subCategories?.map((item: string, index: number) => (
                                            item !== "" && <Badge key={index} variant="light" radius={"xs"} color="gray">{item}</Badge>
                                        ))}
                                    </Group>
                                </Group>
                            </Grid.Col>
                            : null
                        }

                        {product?.offers && product?.offers?.length > 0
                            ? <Grid.Col span={12} >
                                <Stack justify='flex-start' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{t('drawer.product.label15')}</Text>
                                    {product?.offers?.map((item: any, index: number) => (
                                        <Stack justify='flex-start' align='flex-start' gap={5} p={5} style={{border: "1px solid #ddd", borderRadius: 5}}>
                                            <Group justify='space-between' align='center' w={"100%"}>
                                                <Text size={'sm'} c={"gray.8"} fw={600}>{item?.name}</Text>
                                                <Text size={'xs'} c={"#323232"} fw={600}>{item?.price} {t("currency")}</Text>
                                            </Group>
                                            <Group justify='flex-start' align='center' gap={3}>
                                                <Text size={'sm'} c={"gray.7"}>{t('drawer.product.label16')}</Text>
                                                <Text size={'sm'} c={"gray.8"} fw={600}>{item?.quantity}</Text>
                                            </Group>
                                            <Group justify='flex-start' align='center' gap={3}>
                                                <Text size={'sm'} c={"gray.7"}>{t('drawer.product.label17')}</Text>
                                                {item?.freeShipping
                                                    ? <IconCircleCheck size={15} color='green' />
                                                    : <IconCircleX size={15} color='red' />
                                                }
                                            </Group>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Grid.Col>
                            : null
                        }
                    </Grid>
                </Tabs.Panel>
                <Tabs.Panel value="tab2" >
                    {product?.purchaseHistory?.length > 0
                        ? <Timeline mt={50} active={product?.purchaseHistory?.length} bulletSize={18} lineWidth={1.5}>
                            {product?.purchaseHistory?.map((item: any, index: number) => (
                                <Timeline.Item
                                    key={index}
                                    //@ts-ignore
                                    title={
                                        <Group justify='space-between' align='center'>
                                            <Badge fw={'normal'}>{item?.quantity} قطعة</Badge>
                                            <Text size="xs">{dayjs(item?.createdAt).locale("ar").fromNow()}</Text>
                                        </Group>
                                    }
                                    styles={{
                                        itemBody: {
                                            background: "#fff",
                                            padding: 10
                                        },
                                        itemTitle: {
                                            fontSize: 14
                                        }
                                    }}
                                >
                                    <Text c="dark" size="sm" mt={10} mb={5}>
                                        <Text c="dimmed" span me={5}>سعر القطعة:</Text>
                                        {item?.price} دج
                                    </Text>
                                    <Text c="dark" size="sm">
                                        <Text c="dimmed" span me={5}>السعر الكلي:</Text>
                                        {item?.price * item?.quantity} دج
                                    </Text>
                                    {item?.variants?.length > 0
                                        ? <Accordion
                                            mt={20} variant="filled"
                                            style={{background: theme.colors.gray[0]}}
                                            styles={{
                                                label: {
                                                    fontWeight: 700,
                                                    color: theme.colors.gray[7],
                                                    fontSize: 11
                                                },
                                                icon: {
                                                    color: theme.colors.gray[5]
                                                },
                                                content: {
                                                    padding: 0
                                                }
                                            }}
                                        >
                                            <Accordion.Item value="1" style={{background: theme.colors.gray[0]}}>
                                                <Accordion.Control style={{borderBottom: `1px solid #645cbb7d`}}>تفاصيل الشراء</Accordion.Control>
                                                <Accordion.Panel p={10}>
                                                    <Stack gap={0}>
                                                        {item?.variants?.map((item2: any, index2: number) => (
                                                            <Group key={index2} justify='space-between' style={{borderBottom: `1px dotted #645cbb7d`}} py={10}>
                                                                <Group justify='flex-start'>
                                                                    <Box bg={item2?.color || "#000"} w={16} h={16} style={{borderRadius: 8, border: `1px solid #868e96`}} />
                                                                    <Text size={"sm"} fw={700} c={"gray.7"} >{item2?.size || ""}</Text>
                                                                </Group>

                                                                <Text size={"sm"} fw={700} c={"gray.7"} >
                                                                    {item2?.quantity}قطعة
                                                                </Text>
                                                            </Group>
                                                        ))}
                                                    </Stack>
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        </Accordion>
                                        : null
                                    }
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        : null
                    }
                </Tabs.Panel>
            </Tabs>
        </Drawer>
    );
}