import {
    ActionIcon,
    Box,
    Button,
    ColorInput,
    Divider, FileButton,
    Grid,
    Group,
    NumberInput, Stack,
    Image,
    Text,
    TextInput, Badge
} from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import {IconCheck, IconPlus, IconTrash, IconUpload, IconX} from "@tabler/icons-react";
import classes from "../../styles/AddProduct.module.css"
import { z } from 'zod';

import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useProducts } from "../../api";
import Wilayas from "../../helper/wilayas.json";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

interface FormDataI {
    price: number;
    quantity?: number;
    variants: {
        color: string | null;
        size: string | null;
        oldQuantity: number;
        quantity: number;
    }[]
}

export const AddQuantityProduct = (props: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values, setFieldValue, removeListItem} = useForm<FormDataI>({
        initialValues: {
            price: 0, quantity: 0, variants: []
        }
    });

    useEffect(() => {
        if (props.opened && "data" in props && props.data !== null) {
            const product = props.data

            if (product?.variants && product?.variants?.length > 0) {
                const listVariants: { color: string | null, size: string | null, oldQuantity: number, quantity: number }[] = []
                for (let i = 0; i < product?.variants?.length; i++) {
                    const variant = product?.variants[i]
                    listVariants.push({ ...variant, oldQuantity: variant.quantity, quantity: 0 })
                }

                setValues({ variants: listVariants })
            }
        }
    }, [props.opened, props.data])

    const onSubmitForm = ({price, quantity, variants}: any) => {
        setLoading(true)
        
        client.put(`/addPurchaseHistory/${props.data._id}`, {
            price,
            quantity: variants.length === 0 ? quantity : variants.reduce((old: any, current: any) => old + current.quantity, 0),
            variants
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.updateProduct.alert01'), color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: t('modals.updateProduct.alert02'), color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box px={10}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid w={"100%"} mb={values.variants.length === 0 ? 0 : 20}>
                        <Grid.Col span={12} >
                            <Box bg={"#fff"} p={20} style={{borderRadius: 5}}>
                                <Group justify='space-between'>
                                    <Group>
                                        <Text size={"sm"} c={"gray.7"} >{"المخزون:"}</Text>
                                        <Text fw={400} >
                                            {props.data?.quantity} <Text span={true} size={"xs"} >قطعة</Text>
                                        </Text>
                                    </Group>
                                    <Divider orientation="vertical" />
                                    <Group>
                                        <Text size={"sm"} c={"gray.7"} >{"الكمية الجديدة:"}</Text>
                                        <Text fw={400} >
                                            {values.variants.length === 0
                                                ? values.quantity
                                                : values.variants.reduce((old: any, current: any) => old + current.quantity, 0)
                                            } <Text span={true} size={"xs"} >قطعة</Text>
                                        </Text>
                                    </Group>
                                    <Divider orientation="vertical" />
                                    <Group>
                                        <Text size={"sm"} c={"gray.7"} >{"الكمية الاجمالي:"}</Text>
                                        <Text fw={400} >
                                            {values.variants.length === 0
                                                ? values.quantity + props.data?.quantity
                                                : values.variants.reduce((old: any, current: any) => old + current.quantity, 0) + props.data?.quantity
                                            } <Text span={true} size={"xs"} >قطعة</Text>
                                        </Text>
                                    </Group>
                                </Group>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <NumberInput
                                label={"سعر الشراء"}
                                placeholder={"سعر الشراء"}
                                min={0}
                                {...getInputProps(`price`)}
                            />
                        </Grid.Col>
                        {values.variants.length === 0 && (
                            <Grid.Col span={12} >
                                <NumberInput
                                    label={"الكمية المضافة"}
                                    placeholder={"الكمية المضافة"}
                                    min={0}
                                    {...getInputProps(`quantity`)}
                                />
                            </Grid.Col>
                        )}
                    </Grid>
                    {values.variants.map((item, index) => (
                        <Stack gap={5} mb={10}>
                            <Group
                                justify='space-between'
                                style={{
                                    background: "#fff",
                                    border: `1.5px solid #fcd0087d`,
                                    borderRadius: 3,
                                    padding: "5px 10px"
                                }}
                            >
                                <Group justify='flex-start' >
                                    <Text size={"sm"} c={"gray.7"} >{"اللون:"}</Text>
                                    <Box style={{borderRadius: 3, border: `1px solid #ccd2d9`}} bg={getInputProps(`variants.${index}.color`).value} w={30} h={30} />
                                    <Text size={"sm"} c={"gray.7"} >{"الحجم:"}</Text>
                                    <Badge fw={400} radius={3} >{getInputProps(`variants.${index}.size`).value}</Badge>
                                    <Text size={"sm"}>({getInputProps(`variants.${index}.oldQuantity`).value} قطعة)</Text>
                                    <Divider orientation="vertical" />
                                    <Text size={"sm"} c={"gray.7"} >{"الكمية الجديدة:"}</Text>
                                    <Text fw={400} >({
                                        getInputProps(`variants.${index}.oldQuantity`).value + getInputProps(`variants.${index}.quantity`).value
                                    } قطعة)</Text>
                                </Group>
                                <NumberInput
                                    placeholder={"الكمية"}
                                    withAsterisk
                                    {...getInputProps(`variants.${index}.quantity`)}
                                    min={0}
                                    w={80}
                                />
                            </Group>
                        </Stack>
                    ))}
                </form>
            </Box>
        </Modal>
    );
};