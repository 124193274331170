import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDisclosure} from "@mantine/hooks";
import {useProducts} from "../../api";
import {searchSortedData, sortedData} from "../../lib/sort";
import {
    ActionIcon, Button,
    Container,
    Group,
    Popover,
    SegmentedControl,
    Select,
    Stack,
    Text,
    TextInput,
    Tooltip
} from "@mantine/core";
import {IconBox, IconCalendar, IconFilter, IconLock, IconSearch, IconUser, IconX} from "@tabler/icons-react";
import {HeadPage} from "../../componants";
import classes from "../../styles/Product.module.css";
import {ProductsTabel, StockTable} from "../../componants/Tables";
import {
    AddProduct, AddQuantityProduct,
    DeleteProduct,
    UpdateAdditionalInformation,
    UpdateProduct,
    UpdateVariantProduct
} from "../../componants/Modal";
import {ShowProductDrawer} from "../../componants/Drawer";
import Cookies from 'universal-cookie';
import {DatePickerInput} from "@mantine/dates";
import dayjs from "dayjs";
import {useForm} from "@mantine/form";
const cookies = new Cookies(null, { path: '/' });


export function Stock() {
    const { t } = useTranslation();
    // const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState<any[]>([]);
    const [productsSorted, setProductsSorted] = useState<any[]>([]);
    const [value, setValue] = useState('new');
    const [searsh, setSearsh] = useState('');
    const [selectedData, setSelectedData] = useState(null);
    const [openedAddModal, { open: openAddModal, close: closeAddModal }] = useDisclosure(false);
    const [openedEditModal, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
    const [openedEditVariantModal, { open: openEditVariantModal, close: closeEditVariantModal }] = useDisclosure(false);
    const [openedEditAdditionalInfoModal, { open: openEditAdditionalInfoModal, close: closeEditAdditionalInfoModal }] = useDisclosure(false);
    const [openedAddQuantityModal, { open: openAddQuantityModal, close: closeAddQuantityModal }] = useDisclosure(false);
    const [openedDeleteModal, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
    const [openedShowModal, { open: openShowModal, close: closeShowModal }] = useDisclosure(false);
    const [role, setRole] = useState("");

    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: {
            productId: "", state: "", date: [], status: "", confirmedBy: ""
        }
    });
    const [openedPopover, setOpenedPopover] = useState(false);
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    const {loading, error, data: dataProducts, refetch} = useProducts({
        idStore: cookies.get("id") || ""
    })

    useEffect(() => {
        if (dataProducts.length >= 0) {
            let filterData = dataProducts.filter((item: any) => item.posting === true)
            setProducts(filterData)
        }
    }, [dataProducts])

    useEffect(() => {
        if (products && products?.length >= 0) {
            setValue("new")
            const toDay = new Date()
            let filterData = products.filter((item: any) => {
                const date = new Date(item.createdAt)
                const dateAfterWeek = date.getDate() - 7
                return toDay >= new Date(new Date().setDate(dateAfterWeek))
            })
            const newData = sortedData(filterData)
            setProductsSorted(newData)
        }
    }, [products])

    useEffect(() => {
        if (["new", "old", "low"].includes(value)) {
            let filterData = []
            const toDay = new Date()

            if (value === "new") {
                filterData = products.filter((item: any) => {
                    const date = new Date(item.createdAt)
                    const dateAfterWeek = date.getDate() - 7
                    return toDay >= new Date(new Date().setDate(dateAfterWeek))
                })
            } else if (value === "old") {
                filterData = products.filter((item: any) => {
                    const date = new Date(item.createdAt)
                    const dateAfterWeek = date.getDate() - 7
                    return toDay < new Date(new Date().setDate(dateAfterWeek))
                })
            } else if (value === "low") {
                filterData = products.filter((item: any) => item.quantity <= 20)
            }

            filterData = sortedData(filterData)

            if (searsh && searsh !== "") {
                filterData = searchSortedData(filterData, ["name"], searsh)
            }

            setProductsSorted(filterData)
        } else {
            let filterData = products
            filterData = sortedData(products)
            if (searsh && searsh !== "") {
                filterData = searchSortedData(filterData, ["name"], searsh)
            }

            setProductsSorted(filterData)
        }
    }, [value, searsh])

    const onFilter = ({productId, date, confirmedBy}: any) => {
        const query: any = {};
        if (productId) query.productId = productId;

        if (date && date?.length === 2 && date?.[0] && date?.[1]) {
            query.dateStart = dayjs(date?.[0]).format("YYYY-MM-DD")
            query.dateEnd = dayjs(date?.[1]).format("YYYY-MM-DD")
        }
        if (confirmedBy) query.confirmedBy = confirmedBy;

        // grtOrders({
        //     limit, page, sort, status,
        //     ...query
        // })
    }

    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={"المخزون"}
                links={[
                    { title: t('productsPage.links.link01'), href: '/dashboard' },
                    { title: "قائمة المنتجات بالمخزون", href: '' }
                ]}
                labelCreate={["admin"].includes(role) ? t('productsPage.labelCreate') : ""}
                labelExport={t('productsPage.labelExport')}
                onCreate={["admin"].includes(role) ? openAddModal : () => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            <Group justify='space-between' align='flex-end' mb={20}>
                <Group justify='flex-start' align='center' wrap='nowrap' gap={10}>
                    <SegmentedControl
                        withItemsBorders={false}
                        value={value}
                        onChange={setValue}
                        data={[
                            { label: "المنتجات الجديدة", value: 'new' },
                            { label: "المنتجات القديمة", value: 'old' },
                            { label: "المنتجات القريبة من النفاذ", value: 'low' }
                        ]}
                        styles={{
                            root: {
                                border: "1px solid #E0E2E7",
                                background: "#fff",
                                height: 40,
                                alignItems: "center"
                            },
                            indicator: {
                                background: "#F2F7FB",
                                height: 30, minHeight: 30, maxHeight: 30,
                                boxShadow: "none"
                            }
                        }}
                        classNames={{
                            control: classes.control
                        }}
                    />
                    <Tooltip label={"فلترة"} position='top'>
                        <Popover width={360} opened={openedPopover} onChange={setOpenedPopover} position="right-start" offset={10} withArrow closeOnClickOutside={false} >
                            <Popover.Target>
                                <ActionIcon variant='transparent' size={40} onClick={() => setOpenedPopover(true)}>
                                    <IconFilter size={20} />
                                </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <form onSubmit={onSubmit(onFilter)}>
                                    <Stack gap={5}>
                                        <Group justify='space-between' wrap={"nowrap"}>
                                            <Text size='sm' fw={600}>التاريخ :</Text>
                                            <DatePickerInput
                                                type="range"
                                                leftSection={<IconCalendar size={18} />}
                                                placeholder={"الفترة"}
                                                valueFormat='DD MMM YYYY'
                                                clearable
                                                locale='ar'
                                                styles={{
                                                    input: {width: 260}
                                                }}
                                                {...getInputProps("date")}
                                            />
                                        </Group>

                                        <Group justify='flex-end' mt={10} gap={5}>
                                            <Button color="gray" size='xs' variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={() => setOpenedPopover(false)}>{"إلغاء"}</Button>
                                            <Button color={'#323232'} size='xs' rightSection={<IconFilter size={15} />} type="submit">{"تأكيد"}</Button>
                                        </Group>
                                    </Stack>
                                </form>
                            </Popover.Dropdown>
                        </Popover>
                    </Tooltip>
                </Group>
                <TextInput
                    value={searsh}
                    onChange={(event) => setSearsh(event.currentTarget.value)}
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('productsPage.placeholderInput')}
                    styles={{
                        input: {height: 40}
                    }}
                />
            </Group>
            <StockTable
                data={productsSorted}
                setOpenEditModal={openEditModal}
                setOpenDeleteModal={openDeleteModal}
                setOpenShowModal={openShowModal}
                setOpenEditVariantModal={openEditVariantModal}
                setOpenAdditionalInfoModal={openEditAdditionalInfoModal}
                setOpenAddQuantityModal={openAddQuantityModal}
                setSelectedData={setSelectedData}

                loading={loading}
            />

            <AddProduct title={t('productsPage.addProduct')} refetchData={refetch} opened={openedAddModal} onClose={closeAddModal} />
            <UpdateProduct title={t('productsPage.updateProduct')} refetchData={refetch} data={selectedData} opened={openedEditModal} onClose={closeEditModal} />
            <UpdateVariantProduct title={"اضافة وتعديل المتغيرات"} refetchData={refetch} data={selectedData} opened={openedEditVariantModal} onClose={closeEditVariantModal} />
            <AddQuantityProduct title={"اضافة كمية للمخزون"} refetchData={refetch} data={selectedData} opened={openedAddQuantityModal} onClose={closeAddQuantityModal} />
            <UpdateAdditionalInformation title={"اضافة معلومات اضافية"} refetchData={refetch} data={selectedData} opened={openedEditAdditionalInfoModal} onClose={closeEditAdditionalInfoModal} />
            <DeleteProduct data={selectedData} refetchData={refetch} opened={openedDeleteModal} onClose={closeDeleteModal} />

            <ShowProductDrawer title={t('productsPage.showProduct')} data={selectedData} opened={openedShowModal} onClose={closeShowModal} />
        </>
    );
}